<template>
  <div class="text-white-1">
    <div class="w-screen section-container passionails-banner relative space-top font-kannada" v-show="shouldShowElement">
      <div class="passionails-intro px-4 z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl h-full flex flex-col gap-8 items-center justify-center ml-8 xl:ml-24 2xl:ml-32">
        <p class="text-base xl:text-xl 2xl:text-3xl">At <span class="text-3xl xl:text-4xl 2xl:text-5xl">Passionails</span>, we've curated our
        services to deliver the best nail treatments in the country! From our
        basic nail cleaning to our relaxing hand and foot massages, there
        really is one for everyone.</p>
      </div>
    </div>

    <div class="w-screen passionails-banner-mobile relative space-top font-kannada pb-3" v-show="shouldNotShowElement">
      <div class="passionails-intro absolute ml-3 z-10 w-4/5 bottom-10">
        <p class="text-base xl:text-xl 2xl:text-3xl leading-tight">At <span class="leading-none text-3xl xl:text-4xl 2xl:text-5xl">Passionails</span>, we've curated our
        services to deliver the best nail treatments in the country! From our
        basic nail cleaning to our relaxing hand and foot massages, there
        really is one for everyone.</p>
      </div>
      <div class="">
        <img class="w-full ml-14" src="../../assets/images/home-page/passionails-banner-mobile.png" alt="banner-transparent">
      </div>
    </div>

    <div class="w-screen safe-simple-satisfying-section py-8 px-12 mobileL:px-14 mobileL:py-20 xl:px-14 xl:py-24 2xl:px-20 2xl:py-28 text-center">
      <h3 class="text-white-1 safe-simple-satisfying-text font-kannada text-4xl mobileL:text-6xl xl:text-8xl 2xl:text-10xl">Safe, Simple & Satisfying</h3>
    </div>

    <div class="our-historys-section px-0 mobileL:px-2 py-12 2xl:px-8 2xl:py-20" id="why-passionails">
      <div class="custom-w-8xl mx-auto">
        <div class="grid md:grid-cols-2">
            <div class="our-history-image-container">
              <img class="mx-auto" src="../../assets/images/home-page/pn-our-history.png" alt="Our History">
            </div>
            <div class="our-history-content-container flex flex-col justify-center items-center px-12 mobileL:px-0 py-5 mobileL:items-start">
              <div class="flex flex-row mobileL:flex-col">
                <div class="relative flex">
                  <h3 class="font-extrabold text-3xl mobileL:text-4xl xl:text-6xl font-kannada z-10">Our History</h3>
                  <span class="text-bg"></span>
                </div>
                <img class="w-6 mobileL:w-8 my-4 mobileL:my-6" src="../../assets/images/home-page/shine.png" alt="Shine">
              </div>
              <p class="text-2xl mobileL:text-xl text-center mobileL:text-left xl:text-3xl font-kannada mobileL:font-montserrat">Founded in 2018, Passionails is a new
              professional nail brand that started in Manila, redefining the
              in-store nail care experience with specialized nail counters
              using all-natural products.</p>
            </div>

            <div class="our-history-content-container flex flex-col justify-center items-center mobileL:items-end px-12 mobileL:px-0 py-5 order-2 mobileL:order-1">
              <div class="flex flex-row mobileL:flex-col items-start mobileL:items-end">
                <div class="relative flex">
                  <h3 class="font-extrabold text-3xl mobileL:text-4xl xl:text-6xl font-kannada z-10">About Us</h3>
                  <span class="text-bg"></span>
                </div>
                <img class="w-6 mobileL:w-8 my-4 mobileL:my-6" src="../../assets/images/home-page/shine.png" alt="Shine">
              </div>
              <p class="text-2xl mobileL:text-xl text-center mobileL:text-right xl:text-3xl font-kannada mobileL:font-montserrat">At Passionails, we’re
              changing the notion that nail treatments are for cuticle-deep
              beauty to what it really is - all about taking care of you, in
              the safest, simplest & most satisfying way.</p>
            </div>
            <div class="our-history-image-container order-1 mobileL:order-2">
                <img class="mx-auto" src="../../assets/images/home-page/pn-about-us.png" alt="Our History">
            </div>
        </div>
      </div>
    </div>

    <div class="why-passionails-section px-10 py-16 mobileL:px-7 mobileL:py-7 2xl:p-10 text-center">
      <div class="max-w-3xl xl:max-w-5xl 2xl:max-w-6xl text-white-1 mx-auto flex flex-col justify-center h-full">
        <h3 class="font-extrabold mobileL:font-normal text-4xl mobileL:text-6xl xl:text-8xl 2xl:text-10xl font-kannada">Why Passionails?</h3>
        <p class="text-2xl xl:text-3xl font-kannada">With our commitment to safe, professional and efficient nail care services, our clients can indulge in premium offers at an accessible rate.</p>
      </div>
    </div>

    <div class="testimonials-section w-screen p-5 mobileL:p-10 py-10 mobileL:py-16 bg-green-15 mobileL:bg-pink-12 text-center text-white-1" id="testimonials">
      <div class="custom-w-7xl mx-auto mb-5 mobileL:mb-20">
        <div class="relative flex justify-center">
          <h3 class="font-normal mobileL:font-extrabold text-2xl mobileL:text-5xl 2xl:text-8xl font-kannada z-10">What our client say about us</h3>
          <span class="text-testimonials-bg" v-show="shouldShowElement"></span>
        </div>
      </div>
        <div class="testimonial-container px-16">
          <div class="grid mobileL:grid-cols-3 justify-center items-center mb-5 mobileL:mb-20 w-full mobileL:w-11/12 mx-auto">
              <div class="grid-item pb-4 flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full">
                <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/pn-quote.png" alt="Quotation mark">
                <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I visit Passionails on days that I want a quick nail fix. They're not pricey as well so that's a plus.</p>
                <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">M.</h4>
              </div>

              <div class="grid-item pb-4 flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" v-show="isVisible || shouldShowElement">
                <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/pn-quote.png" alt="Quotation mark">
                <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">Passionails is me and my mom's favorite nail salon. It's near our house and it's relaxing and worth your money.</p>
                <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">P.</h4>
              </div>

              <div class="grid-item flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" v-show="isVisible || shouldShowElement">
                <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/pn-quote.png" alt="Quotation mark">
                <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I love their hand paraffin service 5/5</p>
                <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">K.</h4>
              </div>
          </div>
          <div class="grid mobileL:grid-cols-3 justify-center items-center" v-show="isVisible">
              <div class="grid-item-with-top py-4 mobileL:py-0 mobileL:border-none flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full">
                <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/pn-quote.png" alt="Quotation mark">
                <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I'm so happy that Passionails now offers polygel nails!
                  I can now achieve my peg nails and won't budge.</p>
                <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">L.</h4>
              </div>
          </div>

          <button class="toggle-btn bg-violet-1 mt-5 text-white-1 font-extrabold px-4 xl:px-5 2xl:px-7 py-2 xl:py-3 2xl:py-4 rounded-full text-base xl:text-xl 2xl:text-2xl" @click="showMoreFeedback()" ref="toggleButton">
            Load More!
          </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
        title: "Passionails",
        titleTemplate: "%s",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            vmid: 'description',
            name: "description",
            content:
              "Passionails is your new favorite hangout place for your mani/pedis with the galplas or if want to relax and rejuvenate. Every inch of our space has been carefully planned and created to provide you with the most comfortable experience.",
          },
          { vmid: 'thumbnail', name: "thumbnail", content: "https://lay-bare.com/passionails-logo.png" },
          { property: "og:title", content: "Passionails" },
          { property: "og:site_name", content: "Passionails" },
          { property: "og:url", content: "https://lay-bare.com/passionails" },
          { property: "og:type", content: "website" },
          {
            vmid: 'og:description', 
            property: "og:description",
            content:
              "Passionails is your new favorite hangout place for your mani/pedis with the galplas or if want to relax and rejuvenate. Every inch of our space has been carefully planned and created to provide you with the most comfortable experience.",
          },
          { vmid: 'og:image', property: "og:image", content: "https://lay-bare.com/passionails-logo.png" },

          { vmid: 'twitter:card', name: "twitter:card", content: "summary" },
          { vmid: 'twitter:site', name: "twitter:site", content: "@Lay_Bare" },

          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name: "robots", content: "index,follow" },
        ]
    },

  data() {
        return {
            isVisible: false,
            windowWidth: window.innerWidth,
        }
    },

  computed: {
    shouldShowElement() {
        return this.windowWidth > 480; 
    },

    shouldNotShowElement(){
        return this.windowWidth < 480; 
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
      handleResize() {
          this.windowWidth = window.innerWidth;
      },

      showMoreFeedback() {
          this.isVisible = !this.isVisible;
          this.$refs.toggleButton.innerText = this.isVisible ? 'Load Less!' : 'Load More!';
      }
  }
}
</script>

<style scoped>
.section-container {
    height: 600px;
}

.space-top {
  margin-top: 90px;
}

.passionails-banner {
    background: url('../../assets/images/home-page/passionails-banner.jpg') no-repeat;
    background-size: cover;
}

.safe-simple-satisfying-section {
  background: url('../../assets/images/home-page/pn-banner-2.jpg') no-repeat;
  background-size: cover;
}



.custom-w-8xl {
    max-width: 1000px;
}

.our-historys-section {
  background: url('../../assets/images/home-page/pn-banner-3.jpg') no-repeat;
  background-size: cover;
}

.our-history-image-container img {
    width: 24rem;
}

.text-bg {
  position: absolute;
  width: 100%;
  height: 35px;
  top: 18px;
  left: -5px;
  z-index: 1;
  background: #7D4979;
}

.why-passionails-section {
  height: 400px;
  min-height: 400px;
  background: url('../../assets/images/home-page/pn-banner-4.jpg') no-repeat;
  background-size: cover;
}

.grid-item:not(:nth-child(3n)) {
    border-right: 3px solid #fff;
}

.toggle-btn {
  box-shadow: 0px 4px 4px 0px #00000066;
}

.custom-w-7xl {
    max-width: 800px;
}

.text-testimonials-bg {
  position: absolute;
  width: 100%;
  height: 50px;
  top: 22px;
  left: -5px;
  z-index: 1;
  background: #E1B6EB;
}

@media (max-width: 480px){

    .section-container{
    height: 289px;
    }

    .passionails-banner-mobile {
      background: linear-gradient(180deg, #C87F93 0%, #D8ACBB 57.15%); 
    }
    .space-top {
    margin-top: 60px;
    }

    .safe-simple-satisfying-text{
      font-family: kannada;
      text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .our-history-image-container img {
        width: 222px;
    }

    .text-bg {
      position: absolute;
      width: 100%;
      height: 35px;
      top: 10px;
      left: -5px;
      z-index: 1;
      background: #7D4979;
    }

    .why-passionails-section {
    height: 322;
    min-height: 322px;
    background: url('../../assets/images/home-page/pn-banner-4-mobile.png') no-repeat;
    background-size: 100% 100%;
  }
    
    .grid-item:not(:nth-child(3n)) {
    border-bottom: 3px solid #fff;
    border-right: 0px;
    }
    .grid-item-with-top:not(:nth-child(3n)) {
    border-bottom: 3px solid #fff;
    border-top: 3px solid #fff;
    border-right: 0px;
    }
}

@media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: 800px;
    }

    .our-history-image-container img {
      width: 28rem;
    }

    .text-bg {
      top: 25px;
      left: -5px;
      height: 45px
    }

    .why-passionails-section {
      height: 500px;
      min-height: 500px;
    }

    .custom-w-8xl {
        max-width: 1100px;
    }
}

@media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }

    .custom-w-8xl {
      max-width: 1600px;
    }

    .our-history-image-container img {
      width: 34rem;
    }

    .text-bg {
      top: 30px;
      left: -5px;
      height: 50px
    }

    .why-passionails-section {
      height: 600px;
      min-height: 600px;
    }

    .text-testimonials-bg {
      width: 100%;
      height: 60px;
      top: 40px;
      left: -5px;
    }

    .custom-w-7xl {
      max-width: 1600px;
    }
}
</style>